<template>
  <b-container>
    <div class="card" v-if="!isLoading">
      <h1 class="m-1">Invoices</h1>
      <b-table
        striped
        hover
        responsive
        class="position-relative mb-0"
        :items="invoices"
        :fields="fields"
      >
        <template #cell(type_payment)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(status_payment)="data">
          <span
            v-if="
              data.item.type_payment === 'Paypal' ||
              data.item.type_payment === 'Cart' ||
              data.item.type_payment === 'coupon' ||
              data.value
            "
          >
            <span v-if="data.value">
              <b-badge variant="success">Paid</b-badge>
            </span>
            <span v-else> <b-badge variant="warning">Not Paid</b-badge> </span>
          </span>
          <b-form-select
            v-else
            v-model="selected"
            @change="UpdateStatus(data.item)"
            :options="options"
            size="sm"
          ></b-form-select>
        </template>
        <template #cell(created_at)="data">
          <span v-if="data.value">
            {{ formattedDate(data.value) }}
          </span>
        </template>
        <template #cell(date_payment)="data">
          <span v-if="data.value">
            {{ formattedDate(data.value) }}
          </span>
        </template>
        <template #cell(amount_after_reduction)="data">
          <span v-if="data.value">
            {{ data.value }}
          </span>
          <span v-else>  
            <b-badge variant="warning">No Reduction</b-badge>
          </span>
        </template>
          <template #cell(action)="data">
          <b-button
            variant="flat-danger"
            class="btn-icon rounded-circle"
            title="Delete"
            @click="setDeleteId(data.item.id)"
          >
            <feather-icon icon="Trash2Icon" size="20" />
          </b-button>
        </template>
      </b-table>
    </div>
    <LoaderVue v-else />
  </b-container>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import LoaderVue from "@/components/Loader/Loader.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  VBTooltip,
  BModal,
  BCardText,
  BContainer,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BContainer,
    VBTooltip,
    BModal,
    BCardText,
    LoaderVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        // {
        //   key: "index",
        //   label: "#",
        //   class: "text-center",
        // },
        {
          key: "reference",
          label: "reference",
          class: "text-center",
        },
        {
          key: "type_payment",
          label: "payment type",
          class: "text-center",
        },
        {
          key: "status_payment",
          label: "payment status",
          class: "text-center",
        },
        {
          key: "date_payment",
          label: "payment date",
          class: "text-center",
        },
        {
          key: "date_expiration",
          label: "expiration date",
          class: "text-center",
        },
        {
          key: "amount_total",
          label: "Amount",
          class: "text-center",
        },
        {
          key: "amount_after_reduction",
          label: "amount reduction",
          class: "text-center",
        },
        {
          key: "email",
          label: "User Mail",
        },
        {
          key: "action",
          label: "actions",
        },
      ],
      invoices: [],
      isLoading: false,
      selected: 0,
      options: [
        { value: 0, text: "Not Paid" },
        { value: 1, text: "Paid" },
      ],
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    formattedDate(dateStr) {
      const dt = moment(dateStr);
      return dt.format("DD-MM-YYYY");
    },
    getInvoices() {
      this.isLoading = true;
      axios
        .get("/invoices")
        .then(({ data }) => {
          this.invoices = this.addIndexToTable(data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;
        return item;
      });
      return t;
    },
    UpdateStatus(item) {
      axios
        .put(`/invoices/${item.id}/update-payment-status`, {
          status_payment: this.selected,
          user_id: item.user_id,
        })
        .then(({ data }) => {
          this.showToast('success','CheckIcon','Updated','Payment Status Updated');
          this.getInvoices();
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    axios
      .post("/isAdmin")
      .then(({ data }) => {
        if (data.status === true) {
          next(true);
        } else if (data.status === false) {
          next("/error-404");
        }
      })
      .catch((error) => {
        next("/login");
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

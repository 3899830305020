<template>
  <div class="loader-overlay">
    <b-spinner label="Loading..." style="width: 6rem; height: 6rem;" variant="primary" />
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue";
export default {
  name: 'laoder',
  components: {
    BSpinner
  }
}
</script>
<style>
.loader-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  margin-top: 12rem;
  /* Add blur effect to the background */
}

/* Center the spinner */
.loader-overlay .spinner-grow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>